<template>
  <div class="nav">
    <div class="nav-menu-wrapper">
      <ul class="menu-list">
        <li
          v-for="(item, index) in menuList"
          :key="index"
          :class="{ activeNav: index == current }"
          @click="changeMenu(index)"
        >
          <div class="block"></div>
          <span class="iconfont" :class="item"></span>
        </li>
      </ul>
    </div>
    <div class="own-pic" @click="userInfoShow">
        <!-- <HeadImg ></HeadImg> -->
        <img :src="imgUrl?imgUrl:'https://th.bing.com/th?id=ODL.3e2fbff4543f0d3632d34be6d02adc93&w=100&h=100&c=12&pcl=faf9f7&o=6&dpr=1.5&pid=13.1'" alt=""  class="img" >
    </div>
  </div>
</template>

<script>
import {USER_HEAD_IMG_URL} from '@/store/mutation-types'
import HeadPortrait from "./HeadPortrait.vue";
import HeadImg from "./HeadImg.vue";
export default {
  components: {
    HeadPortrait,
    HeadImg
  },
  data() {
    return {
      menuList: [
        "icon-xinxi",
        "icon-shezhi"
      ],
      current: 0,
      imgUrl: ''
    };
  },
  methods: {
    changeMenu(index) {
      switch (index) {
        case 0:
          this.$router.push({
            name: "ChatHome",
          }, () => {});
          break;
        case 1:
          this.$router.push({
            name: "Setting",
          }, () => {});
          break;
        default:
          this.$router.push({
            name: "ChatHome",
          });
      }

      this.current = index;
    },
    userInfoShow(){
      this.current = 3
      this.$router.push({
            name: "UserInfo",
          }, () => {});
    }
  },
  mounted(){
    this.imgUrl= JSON.parse(sessionStorage.getItem('userInfo')).avatars
  }
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 90vh;
  position: relative;
  border-radius: 20px 0 0 20px;
  .nav-menu-wrapper {
    position: absolute;
    top: 40%;
    transform: translate(0, -50%);
    .menu-list {
      margin-left: 10px;

      li {
        margin: 40px 0 0 30px;
        list-style: none;
        cursor: pointer;
        position: relative;
        .block {
          background-color: rgb(29, 144, 245);
          position: absolute;
          left: -40px;
          width: 6px;
          height: 25px;
          transition: 0.5s;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          opacity: 0;
        }
        &:hover {
          span {
            color: rgb(29, 144, 245);
          }
          .block {
            opacity: 1;
          }
        }
      }
    }
  }
  .own-pic {
    position: absolute;
    bottom: 10%;
    margin-left: 25px;
  }
}
.activeNav {
  span {
    color: rgb(29, 144, 245);
  }
  .block {
    opacity: 1 !important;
  }
}
.img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: all 0.6s;
}
.img:hover{
  transform: scale(1.5);
}
</style>