<template>
  <div class="main">
    <div v-if="changeFlag">
      <span style="margin-bottom: 20px; display: inline-block"
        >修改密码验证</span
      >
      <el-form :model="changefrom" ref="changefrom" :rules="rules">
        <el-form-item prop="telephone" label="">
          <el-input placeholder="请输入手机号" v-model="changefrom.telephone">
            <i slot="prefix" class="el-icon-mobile-phone"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input placeholder="邮箱" v-model="changefrom.mail">
            <i slot="prefix" class="el-icon-folder"></i>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="width: 100%" @click="verify"
        >验证</el-button
      >
    </div>
    <div v-if="!changeFlag">
      <span style="margin-bottom: 20px; display: inline-block">修改密码</span>
      <el-form :model="newpassword" ref="newpassword" :rules="passwordrules">
        <el-form-item prop="password" label="">
          <el-input placeholder="请输入新密码" v-model="newpassword.password">
            <i slot="prefix" class="el-icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword" label="">
          <el-input
            placeholder="请确认新密码"
            v-model="newpassword.confirmPassword"
          >
            <i slot="prefix" class="el-icon-lock"></i>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="width: 100%" @click="confirm"
        >确定修改</el-button
      >
    </div>
  </div>
</template>
<script>
import { verifypassword, changepassword } from "@/api/getData";
export default {
  name: "",
  data() {
    let checkPass = (rule, value, callback) => {
      setTimeout(() => {
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(value)) {
          callback(new Error("密码为6-20位数字母+数字组合"));
        } else {
          callback();
        }
      }, 600);
    };
    let recheckPass = (rule, value, callback) => {
      setTimeout(() => {
        if (this.newpassword.password != value) {
          callback(new Error("密码不一致!"));
        } else {
          callback();
        }
      }, 600);
    };
    return {
      changeFlag: true,
      changefrom: {
        telephone: "",
        mail: "",
      },
      newpassword: {
        password: "",
        confirmPassword: "",
      },
      rules: {
        telephone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        mail: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
      passwordrules: {
        password: [
          {
            required: true,
            message: "请输入不少于6位数字母+数字组合",
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
          { validator: checkPass, trigger: "blur" },
        ],
        confirmPassword: [{ validator: recheckPass, trigger: "blur" }],
      },
    };
  },
  props: {},
  computed: {},
  components: {},
  created() {},
  mounted() {
    this.changeFlag = true;
  },
  methods: {
    verify() {
      this.$refs.changefrom.validate((valid) => {
        if (valid) {
          verifypassword(this.changefrom).then((res) => {
            if (res.data.code == 0) {
              this.changeFlag = false;
              this.$message.success("验证通过");
            } else {
              this.$message.warning(res.data.msg);
            }
          });
        }
      });
    },
    confirm(){
        this.$refs.newpassword.validate((valid) => {
        if (valid) {
            let data = JSON.parse(JSON.stringify(this.newpassword))
            data.telephone = this.changefrom.telephone
            changepassword(data).then(res=>{
                if(res.data.code==0){
                    window.close()
                }
            })
        }
      });
    }
  },
  watch: {},
};
</script>
<style scoped lang="scss">
.main {
  width: 20%;
  height: 20%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>