import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueRouter from 'vue-router'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import { copyToClipboard } from '@/util/util'
import i18n from '@/config/i18n'
Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.use(ElementUI);
/**
 * 复制
 */

Vue.prototype.$copy = function (value,mes) {
  if (copyToClipboard(value)) {
    this.$message.success(mes)
  }
}
Vue.prototype.$nation = [
  '英语',
  '中文',
  '法语',
  '德语',
  '韩语',
  '日语',
  '葡萄牙语（葡萄牙、巴西）',
  '阿拉伯语',
  '葡萄牙语',
  '俄语',
  '南非荷兰语',
  '阿尔巴尼亚语',
  '阿姆哈拉语',
  '亚美尼亚语',
  '阿塞拜疆语',
  '巴斯克语',
  '白俄罗斯语',
  '孟加拉语',
  '波斯尼亚语',
  '保加利亚语',
  '加泰罗尼亚语',
  '宿务语',
  '中文（简体）',
  '中文（繁体）',
  '科西嘉语',
  '克罗地亚语',
  '捷克语',
  '丹麦语',
  '荷兰语',
  '世界语',
  '爱沙尼亚语',
  '弗里斯兰语',
  '加利西亚语',
  '格鲁吉亚语',
  '古吉拉特语',
  '海地克里奥尔语',
  '豪萨语',
  '夏威夷语',
  '希伯来语',
  '印地语',
  '苗语',
  '匈牙利语',
  '冰岛语',
  '伊博语',
  '印度尼西亚语',
  '爱尔兰语',
  '意大利语',
  '爪哇语',
  '卡纳达语',
  '哈萨克语',
  '高棉语',
  '卢旺达语',
  '库尔德语',
  '吉尔吉斯语',
  '老挝文',
  '拉丁文',
  '拉脱维亚语',
  '立陶宛语',
  '卢森堡语',
  '马其顿语',
  '马尔加什语',
  '马来语',
  '马拉雅拉姆文',
  '马耳他语',
  '毛利语',
  '马拉地语',
  '蒙古文',
  '缅甸语',
  '尼泊尔语',
  '挪威语',
  '尼杨扎语（齐切瓦语）',
  '奥里亚语（奥里亚）',
  '普什图语',
  '波斯语',
  '波兰语',
  '旁遮普语',
  '萨摩亚语',
  '苏格兰盖尔语',
  '塞尔维亚语',
  '塞索托语',
  '修纳语',
  '信德语',
  '僧伽罗语',
  '斯洛伐克语',
  '斯洛文尼亚语',
  '索马里语',
  '巽他语',
  '斯瓦希里语',
  '塔加路语（菲律宾语）',
  '塔吉克语',
  '泰米尔语',
  '鞑靼语',
  '泰卢固语',
  '泰文',
  '土耳其语',
  '土库曼语',
  '乌克兰语',
  '乌尔都语',
  '维吾尔语',
  '乌兹别克语',
  '越南语',
  '威尔士语',
  '班图语',
  '意第绪语',
  '约鲁巴语',
  '祖鲁语',
]


new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')



