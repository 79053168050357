<template>
  <div class="Logindontain">
    <div class="left_contain">
      <img src="./E-1269361-0FCD7D0E.jpg" alt="" />
      <div class="operate_btns">
        <div :class="['sign_up', current ? 'current' : '']" @click="loginbt" @keydown.enter="loginbt">
          登录
        </div>
        <div
          :class="['sign_in', !current ? 'current' : '']"
          @click="registerbt"
        >
          注册
        </div>
      </div>
    </div>
    <div class="right_contain">
      <div style="text-align:center;font-size:24px">南棠AI</div>
      <div class="head_logo"><img src="./head_logo.jpg" alt="" /></div>
      <template v-if="loginoregist">
        <el-form :model="loginform" ref="loginform" :rules="rules">
          <el-form-item prop="telephone" label="">
            <el-input placeholder="请输入手机号" v-model="loginform.telephone">
              <i slot="prefix" class="el-icon-mobile-phone"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password" label="">
            <div style="display: flex">
              <el-input
                @keyup.enter.native="loginok"
                show-password
                type="passwrod"
                placeholder="密码或者手机验证码"
                v-model="loginform.password"
              >
                <i slot="prefix" class="el-icon-lock"></i>
              </el-input>
            </div>
          </el-form-item>
        </el-form>
        <div style="height:30px">
          <el-checkbox style="float:right" v-model="checked">记住密码</el-checkbox>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div>
            <el-button type="success" @click="loginok">立即登录</el-button>
            <a class="forgot-password" @click="go"  target="修改密码">忘记密码?</a>
          </div>
          <div>
            <span>如果还没有账号，请点击</span>
            <el-button
              type="success"
              style="margin-left: 6px"
              @click="registerbt"
              >注册</el-button
            >
          </div>
        </div>
      </template>
      <template v-if="!loginoregist">
        <el-form :model="registform" ref="registform" :rules="registrules">
           <el-form-item prop="username" label="">
            <el-input placeholder="请输入姓名" v-model="registform.username">
              <i slot="prefix" class="el-icon-user"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="telephone" label="">
            <el-input placeholder="请输入手机号" v-model="registform.telephone">
              <i slot="prefix" class="el-icon-mobile-phone"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="mail" label="">
              <el-input placeholder="邮箱" v-model="registform.mail">
                <i slot="prefix" class="el-icon-folder"></i>
              </el-input>
          </el-form-item>
          <el-form-item prop="password" label="">
            <el-input
              placeholder="设置密码"
              v-model="registform.password"
              suffix-icon="el-icon-view"
            >
              <i slot="prefix" class="el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="repassword" label="">
            <el-input
              placeholder="重复密码"
              v-model="registform.repassword"
              suffix-icon="el-icon-view"
              @keyup.enter.native="loginok"
            >
              <i slot="prefix" class="el-icon-lock"></i>
            </el-input>
          </el-form-item>
        </el-form>
        <div style="display: flex; justify-content: space-between">
          <el-button type="success" @click="registe">注册使用</el-button>
          <div>
            <span>如果已经有账号，请点击</span>
            <el-button type="success" style="margin-left: 6px" @click="loginbt"
              >登录</el-button
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { register, login,getAllUser } from "@/api/getData";
export default {
  name: "Login",
  data() {
    let checkName = (rule, value, callback) => {
      setTimeout(() => {
        if(/[^\u4E00-\u9FA5]/g.test(value)){
          callback(new Error('请输入中文姓名'))
        }else {
          callback();
        }
      }, 600);
    };
    let checkMobile = (rule, value, callback) => {
      setTimeout(() => {
        console.log(value);
        if (!/^1[3-9]\d{9}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }, 600);
    };

    let checkPass = (rule, value, callback) => {
      setTimeout(() => {
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(value)) {
          callback(new Error("密码为6-20位数字母+数字组合"));
        } else {
          callback();
        }
      }, 600);
    };
    let recheckPass = (rule, value, callback) => {
      setTimeout(() => {
        if (this.registform.password != value) {
          callback(new Error("密码不一致!"));
        } else {
          callback();
        }
      }, 600);
    };
    return {
      checked:false,
      current: true,
      loginoregist: true,
      LoginForm: {},
      loginform: {
        telephone: "",
        password: "",
      },
      registform: {
        username:"",
        telephone: "",
        password: "",
        repassword: "",
        mail:""
      },
      rules: {
        telephone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          // { type: "number", message: "必须为数字值" },
          { validator: checkMobile, trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入不少于6位数字母+数字组合",
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
          { validator: checkPass, trigger: "blur" },
        ],
      },
      registrules: {
        username: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        telephone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          // { type: "number", message: "必须为数字值" },
          { validator: checkMobile, trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入不少于6位数字母+数字组合",
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
          { validator: checkPass, trigger: "blur" },
        ],
        repassword: [{ validator: recheckPass, trigger: "blur" }],
        mail:[{ required: true, message: '请输入邮箱', trigger: 'blur' }, { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
      },
      url:process.env.VUE_APP_BASE_WEB + '/#/changePassword'
    };
  },
  created() {},
  mounted(){
    const time = new Date()
    const oldTime = new Date(localStorage.getItem('expirationDate'))
    if(!oldTime || time > oldTime){
        localStorage.removeItem('userInfo')
        this.loginform.telephone = null
        this.loginform.password = null
        this.checked = true
    }else {
      this.loginform.telephone = JSON.parse(localStorage.getItem('userInfo')).telephone
      this.loginform.password = JSON.parse(localStorage.getItem('userInfo')).password
      this.checked = false
    }
  },
  methods: {
    loginbt() {
      this.loginoregist = true;
      this.current = true;
      this.loginform.telephone = "";
      this.loginform.password = "";
      this.$nextTick(() => {
        this.$refs.loginform.clearValidate();
      });
    },
    loginok() {
      this.$refs.loginform.validate((valid) => {
        if (valid) {
          console.log(this.loginform);

          login(this.loginform)
            .then((res) => {
              if(res.code==-1){
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                return
              } 
              if (res.data.telephone != "") {
                sessionStorage.setItem('userInfo',JSON.stringify(res.data))
                localStorage.setItem('userInfo',JSON.stringify(res.data))
                if(this.checked){
                  const expirationDate = new Date();
                  expirationDate.setDate(expirationDate.getDate() + 7);//存一个时间为当前记住密码的7天之后
                  localStorage.setItem('expirationDate',expirationDate)
                }
                this.$message({
                  message: "登录成功!",
                  type: "success",
                });
                getAllUser().then(res=>{
                  if(res.code) return
                  this.$router.push("./ChatHome");
                  localStorage.setItem('userList',JSON.stringify(res.data.data))
                })
              }
            })
            .catch((err) => {
              this.$message({
                message: err,
                type:"error",
            })});
        }
      });
    },
    registerbt() {
      this.loginoregist = false;
      this.current = false;
      this.registform.telephone = "";
      this.registform.password = "";
      this.registform.repassword = "";
      this.registform.username = "";
      this.$nextTick(() => {
        this.$refs.registform.clearValidate();
      });
    },
    registe() {
      this.$refs.registform.validate((valid) => {
        if (valid) {
          register(this.registform)
            .then((res) => {
              if(res.code == -1){
                this.$message({
                  message: "当前手机号已注册，请直接登录！!",
                  type: "warning",
                });
                this.loginbt()
              }
              if (res.code == 0) {
                this.$message({
                  message: "注册成功!",
                  type: "success",
                });
                localStorage.setItem('userInfo',JSON.stringify(res.data))
                this.$router.push("./ChatHome");
              }
            })
            .catch((err) => {
              console.log(err);
              this.$message({
                  message: "注册失败",
                  type: "error",
                })
            });
        }
      });
    },
    go(){
      window.open(this.url,'修改密码')
    }
  },
};
</script>

<style lang="scss" scoped>
.Logindontain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  .left_contain {
    position: relative;
    width: 66%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
    .operate_btns {
      position: absolute;
      top: 10.8%;
      right: 0;
      z-index: 2;
      .sign_up {
        display: block;
        width: 106px;
        height: 55px;
        line-height: 55px;
        margin: 0 0 6px;
        padding: 0 25px 0 42px;
        border-radius: 30px 0 0 30px;
        color: #fff;
        font-size: 18px;
        transition: all 0.3s ease;
        cursor: pointer;
      }
      .sign_in {
        display: block;
        width: 106px;
        height: 55px;
        line-height: 55px;
        margin: 0 0 6px;
        padding: 0 25px 0 42px;
        border-radius: 30px 0 0 30px;
        color: #fff;
        font-size: 18px;
        transition: all 0.3s ease;
        cursor: pointer;
      }
      .current {
        background-color: #ffffff;
        color: #000;
      }
    }
  }
  .right_contain {
    width: 34%;
    max-width: 50%;
    max-height: 100%;
    padding: 5vh 2% 6vh;
    overflow: auto;
    background: #fff;
   
    .head_logo {
      text-align: center;
      margin-bottom: 5.76vh;
      img {
        width: 30%;
        height: 30%;
      }
    }
  }
}
.forgot-password {
  color:#ccc;
  margin-left: 10px;
  text-decoration: none; /* 取消默认的下划线样式 */
}

.forgot-password:hover {
  color: red; /* 鼠标悬停时的文字颜色 */
  text-decoration: underline; /* 鼠标悬停时添加下划线 */
}
</style>
