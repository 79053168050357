<template>
  <div id="app">
     <router-view />
  </div>
</template>

<script>
import "@/assets/font/font.css"


export default {
  name: 'App',
}
</script>

<style lang="scss">
@import url(./assets/font/iconfont.css);
.iconfont {
  font-family: "iconfont" !important;
  font-style: normal;
  font-size: 25px;
  vertical-align: middle;
  color: rgb(117,120,137);
  transition: .3s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  padding: 0;
  margin: 0;
  font-family: 'SSFY';
}


#app {
  width: 100vw;
  height: 100vh;
  //background: url("@/assets/img/bj.png") no-repeat;
  background-size:cover;
  position:absolute;
      
}


::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
body{
 .el-tooltip__popper{
  width: 30% !important
}
}
</style>