import VueRouter from 'vue-router'
import Login from '../view/pages/login/index.vue'
import changePassword from '../view/pages/login/changepassword.vue'
import Layout from '../view/home.vue'
const router= new VueRouter({
    routes: [
        {
            path: "/",
            redirect: "/Login",
        },
        {
            path: "/changePassword",
            name: "changePassword",
            component: changePassword
        },
        {
            path: "/",
            component: Layout,
            children: [
                {
                    path: '/ChatHome',
                    component: () =>
                    import('../view/pages/chatHome/index.vue'),
                    name: 'ChatHome',
                },
                {
                    path: "/Setting",
                    name: "Setting",
                    component: () =>
                    import('../view/pages/setting.vue'),
                },
                {
                    path: "/UserInfo",
                    name: "UserInfo",
                    component: () =>
                    import('../view/pages/user/userInfo.vue'),
                } ,
            ]
        },       
        {
            path: "/Login",
            name: "Login",
            component: Login
        } 
    ]
})
// 全局路由守卫
router.beforeEach((to, from, next) => {
    let isLogin = JSON.parse(sessionStorage.getItem('userInfo'))  // 是否登录
    if(to.name == 'Login'){
        if(isLogin){
            next('/ChatHome')
        }else {
            next()
        }
    }else if(to.name!=='Login'){
        if(isLogin!==null){
            next()
        }else {
           if(to.name=='changePassword'&& (from.name==null || from.name=='Login')){
            next()
           }else {
            next('/Login')
           }
        }
    }
});
export default router